<template>
  <div>
    <v-dialog v-model="filtro.filtro" width="fit-content" @click:outside="() => fecharFiltro()">
      <v-card v-on:keyup.enter="carregaFiltro()">
        <div class="d-flex align-center">
          <v-card-title>
            <v-icon class="mr-4" >fas fa-filter</v-icon>
            Filtrar {{ filtro.name }}
          </v-card-title>
          <v-spacer></v-spacer>

          <v-btn class="mr-2" icon @click="() => fecharFiltro()">
            <v-icon color="red">fas fa-times</v-icon>
          </v-btn>
        </div>

        <v-card-text class="mt-4">
          <v-row dense>
            <v-col cols="12" md="3">
              <v-text-field color="#018454" outlined append-icon="fas fa-fingerprint" label="Número Pedido" type="text"
                autocomplete="off" v-model="filtros.codigo" clearable></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field color="#018454" outlined append-icon="fas fa-dolly" label="Pedido Canal Venda" type="text"
              autocomplete="off" v-model="filtros.pedido_canal_venda" clearable></v-text-field>
            </v-col>

            <v-col cols="12" md="3" v-if="filtro.cod_transportadora">
              <v-select color="#018454" outlined append-icon="fas fa-shipping-fast" label="Transportadoras"
                v-model="filtros.cod_transportadora" :items="transportadoras" item-text="nome"
                item-value="codigoSisfrete" clearable></v-select>
            </v-col>
            
            <v-col cols="12" md="3">
              <v-text-field color="#018454" outlined append-icon="fas fa-shopping-cart" label="Canal de Vendas" type="text" autocomplete="off"
                v-model="filtros.canal_venda" clearable></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-select color="#018454" outlined append-icon="fas fa-shopping-cart" label="Sub Canal" type="text" autocomplete="off"
                v-model="filtros.sub_canal" clearable :items="sub_canais" item-value="sub_canal" item-text="sub_canal"></v-select>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field color="#018454" outlined append-icon="fas fa-map-marked-alt" label="Rastreio" type="text" autocomplete="off"
                v-model="filtros.numero_rastreio" clearable></v-text-field>
            </v-col>
            <v-col cols="12" md="3" v-if="filtro.pi">
              <v-text-field color="#018454" outlined append-icon="fas fa-clock" label="PI" type="number" autocomplete="off"
                v-model="filtros.pi" clearable></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field append-icon="fas fa-calendar-alt" outlined type="text" name="considerada"
                label="Data considerada" autocomplete="off" :value="filtros.dt_considerada_inicio != '' &&
                filtros.dt_considerada_final != '' ? 'De ' + formatarData(filtros.dt_considerada_inicio) + 
                ' até ' + formatarData(filtros.dt_considerada_final) : ''" hide-details
                @click="() => modal.dt_considerada = true" color="#018656" :disabled="disableCons">
              </v-text-field>
              <v-checkbox color="#018456" class="pt-0 mt-2" v-model="filtros.dt_considerada_null" dense label="Sem Data"></v-checkbox>

              <v-dialog v-model="modal.dt_considerada" width="fit-content">
                <v-card outlined style="max-width: 800px; height: 100%; max-height: 540px;">
                  <v-card-text class="mt-6">
                    <v-card>
                      <v-card-text style="padding-top: 10px;">
                        <v-row dense>
                          <v-col>
                            <h1
                              style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                              Data inicial
                            </h1>
                            <v-date-picker color="#018656" v-model="filtros.dt_considerada_inicio" size="100"
                              width="100%">
                            </v-date-picker>
                          </v-col>

                          <v-col>
                          <h1
                            style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                            Data final
                          </h1>
                          <v-date-picker color="#018656" v-model="filtros.dt_considerada_final" size="100"
                            width="100%">
                          </v-date-picker>
                        </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <v-row style="margin-top: 10px;" dense justify="end">
                      <v-col cols="12" sm="6">
                        <v-btn color="#018656" style="color: #FFFFFF; width:100%; margin-bottom: 10px;"
                          @click="modal.dt_considerada = false">
                          CONFIRMAR
                        </v-btn>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-btn color="#FF5252" outlined style="color: #FF5252; width:100%; margin-bottom: 10px;"
                          @click="modal.dt_considerada = false, filtros.dt_considerada_inicio = '', filtros.dt_considerada_final = ''">
                          CANCELAR E REMOVER
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field append-icon="fas fa-calendar-alt" outlined type="text" name="prevista"
                label="Data prevista" autocomplete="off" :value="filtros.dt_prevista_inicio != '' &&
                filtros.dt_prevista_final != '' ? 'De ' + formatarData(filtros.dt_prevista_inicio) + 
                ' até ' + formatarData(filtros.dt_prevista_final) : ''" hide-details
                @click="() => modal.dt_prevista = true" color="#018656" :disabled="disablePrev">
              </v-text-field>
              <v-checkbox color="#018456" class="pt-0 mt-2" v-model="filtros.dt_prevista_null" dense label="Sem Data"></v-checkbox>

              <v-dialog v-model="modal.dt_prevista" width="fit-content">
                <v-card outlined style="max-width: 800px; height: 100%; max-height: 540px;">
                  <v-card-text class="mt-6">
                    <v-card>
                      <v-card-text style="padding-top: 10px;">
                        <v-row dense>
                          <v-col>
                            <h1
                              style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                              Data inicial
                            </h1>
                            <v-date-picker color="#018656" v-model="filtros.dt_prevista_inicio" size="100"
                              width="100%">
                            </v-date-picker>
                          </v-col>

                          <v-col>
                          <h1
                            style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                            Data final
                          </h1>
                          <v-date-picker color="#018656" v-model="filtros.dt_prevista_final" size="100"
                            width="100%">
                          </v-date-picker>
                        </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <v-row style="margin-top: 10px;" dense justify="end">
                      <v-col cols="12" sm="6">
                        <v-btn color="#018656" style="color: #FFFFFF; width:100%; margin-bottom: 10px;"
                          @click="modal.dt_prevista = false">
                          CONFIRMAR
                        </v-btn>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-btn color="#FF5252" outlined style="color: #FF5252; width:100%; margin-bottom: 10px;"
                          @click="modal.dt_prevista = false, filtros.dt_prevista_inicio = '', filtros.dt_prevista_final = ''">
                          CANCELAR E REMOVER
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field append-icon="fas fa-calendar-alt" outlined type="text" name="entrega"
                label="Data Entrega" autocomplete="off" :value="filtros.dt_entrega_inicio != '' &&
                filtros.dt_entrega_final != '' ? 'De ' + formatarData(filtros.dt_entrega_inicio) + 
                ' até ' + formatarData(filtros.dt_entrega_final) : ''" hide-details
                @click="modal.dt_entrega = true" color="#018656" :disabled="disableEnt">
              </v-text-field>
              <v-checkbox color="#018456" class="pt-0 mt-2" v-model="filtros.dt_entrega_null" dense label="Sem Data"></v-checkbox>

              <v-dialog v-model="modal.dt_entrega" width="fit-content">
              <v-card outlined style="max-width: 800px; height: 100%; max-height: 540px;">
                <v-card-text class="mt-6">
                  <v-card>
                    <v-card-text style="padding-top: 10px;">
                      <v-row dense>
                        <v-col>
                          <h1
                            style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                            Data inicial
                          </h1>
                          <v-date-picker color="#018656" v-model="filtros.dt_entrega_inicio" size="100"
                            width="100%">
                          </v-date-picker>
                        </v-col>

                        <v-col>
                          <h1
                            style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                            Data final
                          </h1>
                          <v-date-picker color="#018656" v-model="filtros.dt_entrega_final" size="100"
                            width="100%">
                          </v-date-picker>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-row style="margin-top: 10px;" dense justify="end">
                    <v-col cols="12" sm="6">
                      <v-btn color="#018656" style="color: #FFFFFF; width:100%; margin-bottom: 10px;"
                        @click="modal.dt_entrega = false">
                        CONFIRMAR
                      </v-btn>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-btn color="#FF5252" outlined style="color: #FF5252; width:100%; margin-bottom: 10px;"
                        @click="modal.dt_entrega = false, filtros.dt_entrega_inicio = '', filtros.dt_entrega_final = ''">
                        CANCELAR E REMOVER
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field color="#018454" outlined append-icon="fas fa-map-marker-alt" label="Cep Origem" type="number"
                autocomplete="off" v-model="filtros.cep_origem" clearable></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field color="#018454" outlined append-icon="fas fa-map-marker-alt" label="Cep Destino" type="text"
                autocomplete="off" v-model="filtros.cep_destino" clearable></v-text-field>
            </v-col>
            <v-col cols="12" md="3" v-if="$route.name == 'Atrasados' || $route.name == 'Entregues'">
              <v-text-field color="#018454" outlined append-icon="fas fa-truck-loading" label="Código Serviço" type="number"
                autocomplete="off" v-model="filtros.cod_servico" clearable></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined type="text" append-icon="fas fa-calendar-alt" name="ultima_posicao"
                label="Data Última Movimentação" autocomplete="off" :value="filtros.dt_ultima_posicao_inicio != '' &&
                filtros.dt_ultima_posicao_fim != '' ? 'De ' + formatarData(filtros.dt_ultima_posicao_inicio) +
                ' até ' + formatarData(filtros.dt_ultima_posicao_fim) : ''" @click="modal.dt_ultima_posicao = true" color="#018656">
              </v-text-field>

              <v-dialog v-model="modal.dt_ultima_posicao" width="fit-content">
                <v-card outlined style="max-width: 800px; height: 100%; max-height: 540px;">
                  <v-card-text class="mt-6">
                    <v-card>
                      <v-card-text style="padding-top: 10px;">
                        <v-row dense>
                          <v-col>
                            <h1
                              style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                              Data inicial
                            </h1>
                            <v-date-picker color="#018656" v-model="filtros.dt_ultima_posicao_inicio" size="100"
                              width="100%">
                            </v-date-picker>
                          </v-col>

                          <v-col>
                            <h1
                              style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                              Data Final
                            </h1>
                            <v-date-picker color="#018656" v-model="filtros.dt_ultima_posicao_fim" size="100"
                              width="100%">
                            </v-date-picker>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <v-row style="margin-top: 10px;" dense justify="end">
                      <v-col cols="12" sm="6">
                        <v-btn color="#018656" style="color: #FFFFFF; width:100%; margin-bottom: 10px;"
                          @click="modal.dt_ultima_posicao = false">
                          CONFIRMAR
                        </v-btn>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-btn color="#FF5252" outlined style="color: #FF5252; width:100%; margin-bottom: 10px;"
                          @click="modal.dt_ultima_posicao = false, filtros.dt_ultima_posicao_inicio = '',
                          filtros.dt_ultima_posicao_fim = ''">
                          CANCELAR E REMOVER
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="3">
              <v-select outlined append-icon="fas fa-pallet" label="Últ. Mov." v-model="filtros.ultima_posicao"
                clearable color="#018454"></v-select>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field color="#018454" outlined append-icon="fas fa-fingerprint" label="Id Canal Vendas" type="text" autocomplete="off"
              v-model="filtros.id_canal_vendas" clearable></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="end" dense width="100%">
            <v-col cols="12" sm="3" md="2">
              <v-btn type="button" color="#FF5252" style="color: #FFFFFF; width: 100%; height: 50px;"
                @click="() => limparFiltros()">
                <v-icon dense class="mr-2">fas fa-broom</v-icon>
                Limpar
              </v-btn>
            </v-col>

            <v-col cols="12" sm="3" md="2">
              <v-btn type="button" color="#018656" style="color: #FFFFFF; width: 100%; height: 50px;"
                @click="() => carregaFiltro()">
                <v-icon dense class="mr-2">fas fa-filter</v-icon>
                FILTRAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script crossorigin="anonymous">
import { baseApiUrl, baseApiUrlPersys } from "@/global";
import axios from "axios";
import Template from "../Template.vue";
import moment from "moment";

export default {
  name: "Filtro",
  components: {
    Template,
  },
  props: {
    filtro: { type: Object },
  },
  data: () => ({
    codigo_empresa: '',
    nullDisabled: false,
    disableCons: false,
    disablePrev: false,
    disableEnt: false,
    modal: {
      filtro: false,
      dt_considerada: false,
      dt_prevista: false,
      dt_entrega: false,
      dt_ultima_posicao: false,
      rastreio: false,
      detalhes: false,
      pi: false,
      loading: false,
      habilita: false,
    },
    filtros: {
      codigo: "",
      numero_rastreio: "",
      pi: "",
      cod_transportadora: null,
      canal_venda: "",
      dt_prevista_inicio: "",
      dt_prevista_final: "",
      dt_prevista_null: false,
      dt_prevista_disabled: false,
      dt_considerada_inicio: "",
      dt_considerada_final: "",
      dt_considerada_null: false,
      dt_considerada_disabled: false,
      dt_entrega_inicio: "",
      dt_entrega_final: "",
      dt_entrega_null: false,
      dt_entrega_disabled: false,
      cep_origem: "",
      cep_destino: "",
      dt_ultima_posicao_inicio: "",
      dt_ultima_posicao_fim: "",
      ultima_posicao: "",
      id_canal_vendas: "",
      cod_servico: "",
    },
    transportadoras: [],
    sub_canais: [],
  }),
  async mounted() {
    await this.carregarTransportadoras();
    await this.carregarSubCanais();
  },
  methods: {
    limparFiltros() {
      this.filtros.codigo = "";
      this.filtros.numero_rastreio = "";
      this.filtros.pi = "";
      this.filtros.pedido_canal_venda = "";
      this.filtros.cod_transportadora = null;
      this.filtros.canal_venda = "";
      this.filtros.sub_canal = "";
      this.filtros.prazo = "";
      this.filtros.dt_prevista_inicio = "";
      this.filtros.dt_prevista_final = "";
      this.filtros.dt_prevista_null = false;
      this.filtros.dt_prevista_disabled = false;
      this.filtros.dt_considerada_inicio = "";
      this.filtros.dt_considerada_final = "";
      this.filtros.dt_considerada_null = false;
      this.filtros.dt_considerada_disabled = false;
      this.filtros.dt_entrega_inicio = "";
      this.filtros.dt_entrega_final = "";
      this.filtros.dt_entrega_null = false;
      this.filtros.dt_entrega_disabled = false;
      this.filtros.cep_origem = "";
      this.filtros.cep_destino = "";
      this.filtros.dt_ultima_posicao_inicio = "";
      this.filtros.dt_ultima_posicao_fim = "";
      this.filtros.ultima_posicao = "";
      this.filtros.id_canal_vendas = "";
      this.filtros.cod_servico = "";
    },
    fecharFiltro() {
      this.limparFiltros();
      this.$emit('filtroFechar');
    },
    carregaFiltro() {
      this.$emit('filtro', this.filtros );
    },
    carregarTransportadoras() {
      let url = `${baseApiUrlPersys}/transportadora/empresa`;
      axios.get(url, {
        headers: {
          'Token-API': this.$store.state.token_persys
        }
      }).then((res) => {
        this.transportadoras = res.data;
        
        this.transportadoras.sort((a, b) => {
          const nameA = a.nome.toUpperCase();
          const nameB = b.nome.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });
      });
    },

    carregarSubCanais() {
      this.codigo_empresa = Number(this.$store.state.cod_empresa);
      let url = `${baseApiUrl}/objetos-listSubCanais?cod_empresa=${this.codigo_empresa}`;

      axios.get(url)
        .then((res) => {
          this.sub_canais = res.data;
        })
    },

    formatarData(data){
      return moment(data).format('DD/MM/YYYY');
    }
  },

  watch: {
    "filtros.dt_considerada_null": function (val){
      if(val){
        this.filtros.dt_considerada_inicio = '';
        this.filtros.dt_considerada_final = '';
        this.disableCons = true;
      }else{
        this.disableCons = false;
      }
    },

    "filtros.dt_prevista_null": function (val){
      if(val){
        this.filtros.dt_prevista_inicio = '';
        this.filtros.dt_prevista_final = '';
        this.disablePrev = true;
      }else{
        this.disablePrev = false;
      }
    },

    "filtros.dt_entrega_null": function (val){
      if(val){
        this.filtros.dt_entrega_inicio = '';
        this.filtros.dt_entrega_final = '';
        this.disableEnt = true;
      }else{
        this.disableEnt = false;
      }
    }
  }
}
</script>