<template>
  <div>

    <!-- Início filtro -->
    <Filtro
      v-if="modal.filtro"
      v-on:filtro="carregarFiltro"
      v-on:filtroFechar="fecharFiltro"
      :filtro="modal"
    />
    <!-- Fim filtro -->

    <!-- Início detalhes -->
    <Detalhes
      v-if="detmodal"
      v-on:detclose="fecharDetalhes"
      :consulta="objeto"
    />
    <!-- Fim detalhes -->

    <!-- Início principal -->
    <v-card>
      <v-toolbar flat>
        <v-icon> fas fa-clock </v-icon>
        <v-toolbar-title class="pl-4"> Objetos Extravios SLA </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="modal.filtro = true">
          <v-icon>fas fa-search</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon @click="fechar">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
<!--
          <v-col
            cols="2"
            class="px-6 py-6"
            style="padding-bottom: 0px !important"
          >
            <v-select
              dense
              label="Resultados por página"
              :items="grid.objetos.qtdsPaginacao"
              item-text="text"
              item-value="value"
              v-model="grid.objetos.porPagina"
            ></v-select>
          </v-col>
          <v-col
            cols="3"
            class="px-6 py-6"
            style="padding-bottom: 0px !important"
          >
            <v-select
              dense
              label="Ordenar por"
              :items="grid.objetos.ordenacao.colunas"
              item-text="text"
              item-value="value"
              v-model="grid.objetos.coluna"
            ></v-select>
          </v-col>
          <v-col
            cols="2"
            class="px-6 py-6"
            style="padding-bottom: 0px !important"
          >
            <v-select
              dense
              label="Direção"
              :items="grid.objetos.ordenacao.direcao"
              item-text="text"
              item-value="value"
              v-model="grid.objetos.direcao"
            ></v-select>
          </v-col>
          <v-col
            cols="5"
            class="px-6 py-6"
            style="padding-bottom: 0px !important"
          >
            <v-text-field
              dense
              label="Pesquisar"
              type="text"
              autocomplete="off"
              v-model="filtro"
              clearable
            ></v-text-field>
          </v-col>
-->
          <v-col cols="2" class="alinhamento">
            <v-dialog
              ref="modal_data_inicio"
              v-model="modal.data_inicio"
              :return-value.sync="dataInicio"
              persistent
              width="290px"
              :retain-focus="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  prepend-icon="fas fa-calendar-alt"
                  label="Data inicial"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="formatDateInicioGrid"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker v-model="dataInicio" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal.data_inicio = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.modal_data_inicio.save(dataInicio)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>

          <v-col cols="2" class="alinhamento">
            <v-dialog
              ref="modal_data_final"
              v-model="modal.data_final"
              :return-value.sync="dataFinal"
              persistent
              width="290px"
              :retain-focus="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  label="Data final"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="formatDateFinalGrid"
                  clearable
                  append-outer-icon="fas fa-search"
                  @click:append-outer="carregarObjetos()"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dataFinal" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal.data_final = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.modal_data_final.save(dataFinal)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>

        </v-row>
        <v-row>
          <v-col class="px-6 py-6">
            <v-data-table
              dense
              :headers="grid.objetos.cabecalho"
              :items="grid.objetos.items"
              :items-per-page="grid.objetos.porPagina"
              hide-default-footer
              class="elevation-1"
              :loading="grid.objetos.carregando"
              item-key="codigo"
              v-model="grid.objetos.itemSelecionado"
            >
              <template #item.numero_rastreio="{ item }">
                <a @click="carregarDetalhes(item)" style="text-decoration: none" >
                  {{ item.numero_rastreio }}
                </a>
              </template>
              <template #item.reclamacao="{ item }">
                {{
                  item.reclamacao ? (item.reclamacao == 1 ? "Sim" : "SLA") : ""
                }}
              </template>
              <template #item.alerta="{ item }">
                <v-icon v-if="item.alerta">fas fa-exclamation-triangle</v-icon>
              </template>
              <!-- This template looks for headers with formatters and executes them -->
              <template v-for="header in grid.objetos.cabecalho.filter((header) => header.hasOwnProperty('formatter') )" v-slot:[`item.${header.value}`]="{ header, value }" >
                {{ header.formatter(value) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col cols="2">
            <v-btn
              fab
              small
              color="primary"
              dark
              @click="voltarTopo()"
              style="margin-left: 10px"
            >
              <v-icon> fas fa-arrow-up </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8">
            <v-pagination
              v-model="grid.objetos.paginaAtual"
              :length="Math.ceil(grid.objetos.totalRegistros / this.grid.objetos.porPagina)"
              @input="mudarPagina"
              :total-visible="5"
            ></v-pagination>
          </v-col>
          <v-col cols="2">
            <v-select
              dense
              outlined
              label="Resultados por página"
              :items="grid.objetos.qtdsPaginacao"
              item-text="text"
              item-value="value"
              v-model="grid.objetos.porPagina"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Fim principal -->
  </div>
</template>

<script>
import { baseApiUrl } from "@/global";
import axios from "axios";
import moment from "moment";
import Template from "../components/Template.vue";
import Filtro from "../components/objetos/frmfiltro.vue";
import Detalhes from "../components/objetos/frmdetalhes.vue";

export default {
  name: "SLA",
  components: {
    Template,
    Filtro,
    Detalhes,
  },
  data: () => ({
    codigo_empresa: '',
    dataInicio: moment(new Date(new Date().getTime()).setDate(new Date().getDate() - 7) ).format("YYYY-MM-DD"),
    dataFinal: moment(new Date()).format("YYYY-MM-DD"),
    grid: {
      objetos: {
        cabecalho: [
          { text: "#", value: "codigo", sortable: false },
          { text: "Transp.", value: "transportadora", sortable: false },
          { text: "Rastreio", value: "numero_rastreio", sortable: false },
          { text: "Prazo", value: "prazo_entrega", sortable: false },
          { text: "Dt. Cons.", value: "data_considerada", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Dt. Prev", value: "data_prevista", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Dt. Ent.", value: "data_entrega", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Dt. SLA", value: "ExtravioConsiderado", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Servico", value: "Servico", sortable: false },
          { text: "Dt. Mov.", value: "data_ultima_posicao", sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, },
          { text: "Mov.", value: "ultima_posicao", sortable: false },
          { text: "Rec", value: "reclamacao", sortable: false },
          { text: "!", value: "alerta", sortable: false },
        ],
        items: [],
        carregando: false,
        paginaAtual: 1,
        totalRegistros: 0,
        qtdsPaginacao: [10, 15, 50, 100],
        porPagina: 10,
        itemSelecionado: [],
        coluna: "objetos.cod_empresa",
        direcao: "ASC",
        ordenacao: {
          colunas: [
            { text: "Código", value: "objetos.codigo" },
            { text: "Transportadora", value: "transportadoras.transportadora" },
            { text: "Rastreio", value: "objetos.numero_rastreio" },
            { text: "Prazo", value: "objetos.prazo_entrega" },
            { text: "Dt. Cons.", value: "objetos.data_considerada" },
            { text: "Dt. Prev", value: "objetos.data_prevista" },
            { text: "Dt. Ent.", value: "objetos.data_entrega" },
            { text: "Cep Origem", value: "objetos.remetente_cep" },
            { text: "Cep Destino", value: "objetos.destinatario_cep" },
            { text: "Dt. Mov.", value: "objetos.data_ultima_posicao" },
            { text: "Mov.", value: "objetos.ultima_posicao" },
          ],
          direcao: [
            { text: "Asc", value: "ASC" },
            { text: "Desc", value: "DESC" },
          ],
        },
      },
      detalhes: {
        cabecalho: [
          { text: "Status", value: "descricao", sortable: false },
          {
            text: "Data",
            value: "data_hora",
            sortable: false,
            formatter: (x) =>
              x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null,
          },
          { text: "Origem", value: "origem_cidade_uf_local", sortable: false },
          {
            text: "Destino",
            value: "destino_cidade_uf_local",
            sortable: false,
          },
        ],
        items: [],
        carregando: false,
      },
    },
    modal: {
      filtro: false,
      name: "SLA",
      dt_considerada: false,
      dt_prevista: false,
      dt_entrega: false,
      dt_ultima_posicao: false,
      rastreio: false,
      detalhes: false,
      loading: false,
      cod_transportadora: true,
    },
    filtros: {
      codigo: "",
      cod_transportadora: "",
      numero_rastreio: "",
      prazo: "",
      dt_considerada: "",
      dt_prevista: "",
      dt_entrega: "",
      cep_origem: "",
      cep_destino: "",
      dt_ultima_posicao: "",
      ultima_posicao: "",
    },
    transportadoras: [],
    rastreios: [],
    detalhes: [],
    objetoAtual: "",
    loading: false,
    filtro: false,
    filtroFechar: false,
    detalhes: {
      enviado: {
        data_postagem: "",
        data_considerada: "",
        cep: "",
        cidade: "",
      },
      para: {
        nome: "",
        cep: "",
        cidade: "",
        bairro: "",
        rua: "",
      },
      previsao_de_entrega: {
        data: "",
        em_domicilio: "",
      },
      entregue_em: {
        data: "",
      },
    },
    objeto: {
      numero_rastreio: "",
      aba_rastreio: true,
    },
    detmodal: false,
  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
  },
  mounted() {
    this.carregarObjetos();
  },
  computed: {
    formatDateInicio() {
      return this.filtros.data_inicio
        ? moment(this.filtros.data_inicio).format("DD/MM/YYYY")
        : "";
    },
    formatDateFinal() {
      return this.filtros.data_final
        ? moment(this.filtros.data_final).format("DD/MM/YYYY")
        : "";
    },
    formatDateInicioGrid() {
      return this.dataInicio
        ? moment(this.dataInicio).format("DD/MM/YYYY")
        : "";
    },
    formatDateFinalGrid() {
      return this.dataFinal ? moment(this.dataFinal).format("DD/MM/YYYY") : "";
    },
  },
  methods: {
    limparFiltros() {
      this.filtros.codigo = "";
      this.filtros.cod_transportadora = "";
      this.filtros.numero_rastreio = "";
      this.filtros.prazo = "";
      this.filtros.dt_considerada = "";
      this.filtros.dt_prevista = "";
      this.filtros.dt_entrega = "";
      this.filtros.cep_origem = "";
      this.filtros.cep_destino = "";
      this.filtros.dt_ultima_posicao = "";
      this.filtros.ultima_posicao = "";
    },
    fecharFiltro() {
      this.modal.filtro = false;
    },
    carregarFiltro( paramFiltro ) {
      this.modal.filtro = false;
      this.filtros = paramFiltro;
      this.carregarObjetos();
    },
    fechar() {
      this.$router.push("/");
    },
    carregarObjetos() {
      this.grid.objetos.items = [];
      this.grid.objetos.carregando = true;
      let url = `${baseApiUrl}/objetos-slacorreios`;
      url += `?page=${this.grid.objetos.paginaAtual}`;
      url += `&coluna=${this.grid.objetos.coluna}&direcao=${this.grid.objetos.direcao}`;
      url += `&fim=${moment(this.dataFinal).format("YYYY-MM-DD")}&inicio=${moment(this.dataInicio).format("YYYY-MM-DD")}`;

      if (localStorage.getItem("factory"))
        url += `&cod_empresa=${localStorage.getItem("factory")}`;
      if (this.filtros.codigo != "") url += `&codigo=${this.filtros.codigo}`;
      if (this.filtros.cod_transportadora != "")
        url += `&cod_transportadora=${this.filtros.cod_transportadora}`;
      if (this.filtros.numero_rastreio != "")
        url += `&numero_rastreio=${this.filtros.numero_rastreio}`;
      if (this.filtros.prazo != "")
        url += `&prazo_entrega=${this.filtros.prazo}`;
      if (this.filtros.dt_considerada != "")
        url += `&data_considerada=${this.filtros.dt_considerada}`;
      if (this.filtros.dt_prevista != "")
        url += `&data_prevista=${this.filtros.dt_prevista}`;
      if (this.filtros.dt_entrega != "")
        url += `&data_entrega=${this.filtros.dt_entrega}`;
      if (this.filtros.cep_origem != "")
        url += `&remetente_cep=${this.filtros.cep_origem}`;
      if (this.filtros.cep_destino != "")
        url += `&destinatario_cep=${this.filtros.cep_destino}`;
      if (this.filtros.dt_ultima_posicao != "")
        url += `&data_ultima_posicao=${this.filtros.dt_ultima_posicao}`;
      if (this.filtros.ultima_posicao != "")
        url += `&ultima_posicao=${this.filtros.ultima_posicao}`;
      url += `&entregue=N`;
      url += `&atrasado=S`;
      if (Number(this.grid.objetos.porPagina) > 0)
        url += `&por_pagina=${this.grid.objetos.porPagina}`;
      axios
        .get(url)
        .then((res) => {
          this.grid.objetos.items = res.data.dados.data;
          this.grid.objetos.totalRegistros = res.data.dados.total;
          this.grid.objetos.porPagina = Number(res.data.dados.per_page);
        })
        .finally(() => (this.grid.objetos.carregando = false));
    },
    async carregarDetalhes(item) {
      this.objeto.item = item;
      this.objeto.item.cod_empresa = this.codigo_empresa;
      this.objeto.habilita = Number(item.protocolo_retorno) > 0;
      this.objeto.detalhes = true;
      this.detmodal = true;
      this.modal.loading = false;
    },
    fecharDetalhes() {
      this.detmodal = false;
      this.objeto = {
        item: {},
        detalhes: false,
        habilita: false,
        aba_rastreio: true,
      };
    },
    mudarPagina(pagina) {
      this.grid.objetos.paginaAtual = pagina;
      this.carregarObjetos();
    },
    // carregarTransportadoras() {
    //   let url = `${baseApiUrl}/transportadoras`;
    //   axios.get(url).then((res) => {
    //     this.transportadoras = res.data.transportadoras;
    //   });
    // },
    // carregarRastreio(item) {
    //   this.objetoAtual = item.numero_rastreio;
    //   this.modal.loading = true;
    //   this.modal.rastreio = true;
    //   let url = `${baseApiUrl}/rastreios?numero_rastreio=${item.numero_rastreio}`;
    //   axios
    //     .get(url)
    //     .then((res) => {
    //       this.rastreios = res.data.dados;
    //     })
    //     .finally(() => (this.modal.loading = false));
    // },
    voltarTopo() {
      window.scrollTo(0, 0);
    },
    formatarNumeroBR(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    // carregarDetalhes(item) {
    //   this.objetoAtual = item.numero_rastreio;
    //   this.objeto.numero_rastreio = item.numero_rastreio;
    //   this.modal.detalhes = true;
    //   this.grid.detalhes.carregando = true;
    //   let url = `${baseApiUrl}/rastreios?numero_rastreio=${item.numero_rastreio}`;
    //   axios
    //     .get(url)
    //     .then((res) => {
    //       this.grid.detalhes.items = res.data.dados;
    //     })
    //     .finally(() => (this.grid.detalhes.carregando = false));
    //   url = `${baseApiUrl}/detalhes?numero_rastreio=${item.numero_rastreio}`;
    //   axios.get(url).then((res) => {
    //     this.detalhes.enviado.data_postagem = moment(
    //       res.data.dados[0].data_postagem
    //     ).format("DD/MM/yyyy");
    //     this.detalhes.enviado.data_considerada = moment(
    //       res.data.dados[0].data_considerada
    //     ).format("DD/MM/yyyy");
    //     this.detalhes.enviado.cep = res.data.dados[0].remetente_cep;
    //     this.detalhes.enviado.cidade =
    //       res.data.dados[0].remetente_cidade +
    //       "/" +
    //       res.data.dados[0].remetente_estado;
    //     this.detalhes.para.nome = res.data.dados[0].destinatario_nome;
    //     this.detalhes.para.cep = res.data.dados[0].destinatario_cep;
    //     this.detalhes.para.cidade =
    //       res.data.dados[0].destinatario_cidade +
    //       "/" +
    //       res.data.dados[0].destinatario_estado;
    //     this.detalhes.para.bairro = res.data.dados[0].destinatario_bairro;
    //     this.detalhes.para.rua =
    //       res.data.dados[0].destinatario_rua +
    //       ", " +
    //       res.data.dados[0].destinatario_numero;
    //     this.detalhes.previsao_de_entrega.data = moment(
    //       res.data.dados[0].data_prevista
    //     ).format("DD/MM/yyyy");
    //     this.detalhes.previsao_de_entrega.em_domicilio = "Sim";
    //     this.detalhes.entregue_em.data =
    //       res.data.dados[0].data_entrega != null
    //         ? moment(res.data.dados[0].data_entrega).format("DD/MM/yyyy")
    //         : "Não entregue";
    //   });
    // },
    salvarProtocoloReclamacao() {},
    // carregarDetalhesBusca(numero_rastreio) {
    //   this.objetoAtual = numero_rastreio;
    //   this.objeto.numero_rastreio = numero_rastreio;
    //   this.modal.detalhes = true;
    //   this.grid.detalhes.carregando = true;
    //   let url = `${baseApiUrl}/rastreios?numero_rastreio=${numero_rastreio}`;
    //   axios
    //     .get(url)
    //     .then((res) => {
    //       this.grid.detalhes.items = res.data.dados;
    //     })
    //     .finally(() => (this.grid.detalhes.carregando = false));
    //   url = `${baseApiUrl}/detalhes?numero_rastreio=${numero_rastreio}`;
    //   axios.get(url).then((res) => {
    //     this.detalhes.enviado.data_postagem = moment(
    //       res.data.dados[0].data_postagem
    //     ).format("DD/MM/yyyy");
    //     this.detalhes.enviado.data_considerada = moment(
    //       res.data.dados[0].data_considerada
    //     ).format("DD/MM/yyyy");
    //     this.detalhes.enviado.cep = res.data.dados[0].remetente_cep;
    //     this.detalhes.enviado.cidade =
    //       res.data.dados[0].remetente_cidade +
    //       "/" +
    //       res.data.dados[0].remetente_estado;
    //     this.detalhes.para.nome = res.data.dados[0].destinatario_nome;
    //     this.detalhes.para.cep = res.data.dados[0].destinatario_cep;
    //     this.detalhes.para.cidade =
    //       res.data.dados[0].destinatario_cidade +
    //       "/" +
    //       res.data.dados[0].destinatario_estado;
    //     this.detalhes.para.bairro = res.data.dados[0].destinatario_bairro;
    //     this.detalhes.para.rua =
    //       res.data.dados[0].destinatario_rua +
    //       ", " +
    //       res.data.dados[0].destinatario_numero;
    //     this.detalhes.previsao_de_entrega.data = moment(
    //       res.data.dados[0].data_prevista
    //     ).format("DD/MM/yyyy");
    //     this.detalhes.previsao_de_entrega.em_domicilio = "Sim";
    //     this.detalhes.entregue_em.data = moment(
    //       res.data.dados[0].data_entrega
    //     ).format("DD/MM/yyyy");
    //   });
    // },
  },
  watch: {
    "grid.objetos.porPagina": {
      handler: function (val, oldVal) {
        if (val != oldVal) {
          if (val > 0) {
            this.grid.objetos.porPagina = Number(val);
            this.mudarPagina(1);
          } else {
            this.grid.objetos.porPagina = 10;
            this.mudarPagina(1);
          }
        }
      },
    },
    // "grid.objetos.coluna": {
    //   handler: function (val, oldVal) {
    //     if (val != oldVal) {
    //       this.carregarObjetos();
    //     }
    //   },
    // },
    "grid.objetos.direcao": {
      handler: function (val, oldVal) {
        this.carregarObjetos();
      },
    },
    "objeto.numero_rastreio": {
      handler: function (val, oldVal) {
        if (val == null || val == "") {
          this.objetoAtual = "";
          this.objeto.numero_rastreio = "";
          this.detalhes.enviado.data_postagem = "";
          this.detalhes.enviado.data_considerada = "";
          this.detalhes.enviado.cep = "";
          this.detalhes.enviado.cidade = "";
          this.detalhes.para.nome = "";
          this.detalhes.para.cep = "";
          this.detalhes.para.cidade = "";
          this.detalhes.para.bairro = "";
          this.detalhes.para.rua = "";
          this.detalhes.previsao_de_entrega.data = "";
          this.detalhes.previsao_de_entrega.em_domicilio = "";
          this.detalhes.entregue_em.data = "";
          this.grid.detalhes.items = [];
        }
      },
    },
  },
  watch: {
    "grid.objetos.porPagina": {
      handler: function (val, oldVal) {
        if (val != oldVal) {
          if (val > 0) {
            this.grid.objetos.porPagina = Number(val);
            this.mudarPagina(1);
          } else {
            this.grid.objetos.porPagina = 10;
            this.mudarPagina(1);
          }
        }
      },
    },
    // "grid.objetos.coluna": {
    //   handler: function (val, oldVal) {
    //     if (val != oldVal) {
    //       this.carregarObjetos();
    //     }
    //   },
    // },
    "grid.objetos.direcao": {
      handler: function (val, oldVal) {
        this.carregarObjetos();
      },
    },
    "objeto.numero_rastreio": {
      handler: function (val, oldVal) {
        if (val == null || val == "") {
          this.objetoAtual = "";
          this.objeto.numero_rastreio = "";
          this.detalhes.enviado.data_postagem = "";
          this.detalhes.enviado.data_considerada = "";
          this.detalhes.enviado.cep = "";
          this.detalhes.enviado.cidade = "";
          this.detalhes.para.nome = "";
          this.detalhes.para.cep = "";
          this.detalhes.para.cidade = "";
          this.detalhes.para.bairro = "";
          this.detalhes.para.rua = "";
          this.detalhes.previsao_de_entrega.data = "";
          this.detalhes.previsao_de_entrega.em_domicilio = "";
          this.detalhes.entregue_em.data = "";
          this.grid.detalhes.items = [];
        }
      },
    },
  },
};
</script>
